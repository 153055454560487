import React from "react";
import * as ROUTES from "../../../constants/routes";
import { PromotionIcon } from "./icons";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@material-ui/icons/Settings";
import MobileOffIcon from "@material-ui/icons/MobileOff";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Notifications from "@material-ui/icons/Notifications";
import CommentIcon from "@material-ui/icons/Comment";
import PaymentIcon from "@material-ui/icons/Payment";
import AccessTime from "@material-ui/icons/AccessTime";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import AlarmIcon from "@material-ui/icons/Alarm";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import Loading from "../../../components/Loading";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NotesIcon from "@material-ui/icons/Notes";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import FolderIcon from "@material-ui/icons/Folder";
import ListAltIcon from "@material-ui/icons/ListAlt";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";

// import IconButton from '@material-ui/core/IconButton';
// import Divider from '@material-ui/core/Divider';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ModalAlert from "../../../components/ModalComponent";

import useStyles from "./styles";
import { addAdminPermission } from "../../../reducers/shops";

const Navigation = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		open,
		modalOpen,
		width,
		isMenu,
		isPush,
		isReviews,
		isClientList,
		isPromotion,
		isLoadControl,
		isValidPayCard,
		validPromocodesUrl,
		handleClose,
		handleClickSubmit,
		handlePushClick,
		handleMenuClick,
		handleReviewsClick,
		handleClientListClick,
		handlePromotionClick,
		handleLoadControlClick,
		handleSettingsClick,
		handleProductsClick,
		payCardForZone,
		payCardForTerminal,
	} = props;

	return (
		<Drawer
			className={classes.drawer}
			variant={
				width !== "lg" && width !== "md" && width !== "xl"
					? "temporary"
					: "persistent"
			}
			anchor="left"
			open={
				width !== "lg" && width !== "md" && width !== "xl" ? props.open : true
			}
			onClose={props.handleDrawerClose}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<List className={classes.listWrapper}>
				{/*<ListItem button onClick={() => props.handleOpen('menu')}>
          <ListItemIcon className={classes.iconMenu}><AssignmentIcon/></ListItemIcon>
          <ListItemText primary="Меню" />
          {props.openCategory === 'menu' ? <ExpandLess /> : <ExpandMore />}
          {props.openCategory === 'menu' ? <ExpandLess /> : <ExpandMore />}
      </ListItem>*/}

				<ModalAlert
					title={t("navigation.modals.wantLeave")}
					description={t("navigation.modals.dontSave")}
					handleProductsClick
					isReviews
					isPush
					open={modalOpen}
					handleClose={handleClose}
					handleClickSubmit={handleClickSubmit}
				/>

				<Collapse
					className={classes.collapse}
					in={props.openCategory === "menu"}
					timeout="auto"
					unmountOnExit
				>
					<List component="div" className={classes.nestedLink}>
						<NavLink
							to={`/page/test`}
							className={classes.link}
							activeClassName={classes.active}
						>
							<ListItem button className={classes.nested}>
								<ListItemIcon className={classes.iconMenu}>
									{" "}
									<AddShoppingCartIcon />{" "}
								</ListItemIcon>
								<ListItemText primary={t("navigation.products")} />
							</ListItem>
						</NavLink>
						<NavLink
							to={ROUTES.CATEGORIES}
							className={classes.link}
							activeClassName={classes.active}
						>
							<ListItem button className={classes.nested}>
								<ListItemIcon className={classes.iconMenu}>
									{" "}
									<NotesIcon />{" "}
								</ListItemIcon>
								<ListItemText primary={t("navigation.categories")} />
							</ListItem>
						</NavLink>
					</List>
				</Collapse>

				{props.adminPermission && props.adminPermission.menu && (
					<div
						onClick={handleMenuClick}
						className={classes.link}
						style={{
							background: isMenu ? "rgba(50, 109, 255, .1)" : "inherit",
						}}
					>
						{" "}
						<ListItem button>
							<ListItemIcon className={classes.iconMenu}>
								<AssignmentIcon
									className={classes.icon}
									style={{
										color: isMenu ? "#326dff" : "inherit",
										fill: isMenu ? "#326dff" : "",
									}}
								/>
							</ListItemIcon>
							<ListItemText
								style={{ color: isMenu ? "#326dff" : "inherit" }}
								primary={t("navigation.stopList")}
							/>
						</ListItem>
					</div>
				)}
				{props.adminPermission &&
					(props.adminPermission.pushControl ||
						props.adminPermission.pushControl === undefined) && (
						<div
							onClick={handlePushClick}
							className={classes.link}
							style={{
								background: isPush ? "rgba(50, 109, 255, .1)" : "inherit",
							}}
						>
							<ListItem button>
								<ListItemIcon className={classes.iconMenu}>
									<Notifications
										style={{
											color: isPush ? "#326dff" : "inherit",
											fill: isPush ? "#326dff" : "",
										}}
									/>
								</ListItemIcon>
								<ListItemText
									style={{ color: isPush ? "#326dff" : "inherit" }}
									primary={t("navigation.push")}
								/>
							</ListItem>
						</div>
					)}
				{props.adminPermission &&
					(props.adminPermission
						? props.adminPermission.productReviews ||
						  props.adminPermission.appReviews ||
						  props.adminPermission.orderReviews !== undefined
							? props.adminPermission.orderReviews
							: true
						: true) && (
						<div
							onClick={handleReviewsClick}
							className={classes.link}
							style={{
								background: isReviews ? "rgba(50, 109, 255, .1)" : "inherit",
							}}
						>
							<ListItem button>
								<ListItemIcon className={classes.iconMenu}>
									<CommentIcon
										style={{
											color: isReviews ? "#326dff" : "inherit",
											fill: isReviews ? "#326dff" : "",
										}}
									/>
								</ListItemIcon>
								<ListItemText
									style={{ color: isReviews ? "#326dff" : "inherit" }}
									primary={t("navigation.reviews")}
								/>
							</ListItem>
						</div>
					)}
				{props.adminPermission && props.adminPermission.clientsList && (
					<div
						onClick={handleClientListClick}
						className={classes.link}
						style={{
							background: isClientList ? "rgba(50, 109, 255, .1)" : "inherit",
						}}
					>
						<ListItem button>
							<ListItemIcon className={classes.iconMenu}>
								<ListAltIcon
									style={{
										color: isClientList ? "#326dff" : "inherit",
										fill: isClientList ? "#326dff" : "",
									}}
								/>
							</ListItemIcon>
							<ListItemText
								style={{ color: isClientList ? "#326dff" : "inherit" }}
								primary={t("navigation.clientsList")}
							/>
						</ListItem>
					</div>
				)}
				{props.adminPermission && props.adminPermission.promotion && (
					<div
						onClick={handlePromotionClick}
						className={classes.link}
						style={{
							background: isPromotion ? "rgba(50, 109, 255, .1)" : "inherit",
						}}
					>
						<ListItem button>
							<ListItemIcon className={classes.iconMenu}>
								<PromotionIcon
									style={{ fill: isPromotion ? "#326dff" : "inherit" }}
								/>
							</ListItemIcon>
							<ListItemText
								style={{ color: isPromotion ? "#326dff" : "inherit" }}
								primary={t("navigation.promotion")}
							/>
						</ListItem>
					</div>
				)}
				{props.adminPermission &&
					props.adminPermission.controlDeliveryInterval && (
						<div
							onClick={handleLoadControlClick}
							className={classes.link}
							style={{
								background: isLoadControl
									? "rgba(50, 109, 255, .1)"
									: "inherit",
							}}
						>
							<ListItem button>
								<ListItemIcon className={classes.iconMenu}>
									<AlarmIcon
										style={{ fill: isLoadControl ? "#326dff" : "#476282" }}
									/>
								</ListItemIcon>
								<ListItemText
									style={{ color: isLoadControl ? "#326dff" : "#476282" }}
									primary={t("navigation.controlDelivery")}
								/>
							</ListItem>
						</div>
					)}
				<ListItem
					className={classes.settingsListItem}
					button
					onClick={() => handleSettingsClick()}
				>
					<ListItemIcon className={classes.iconMenu}>
						<SettingsIcon />
					</ListItemIcon>
					<ListItemText
						className={classes.listText}
						primary={t("navigation.settings")}
					/>
					{props.openCategory === "settings" ? <ExpandLess /> : <ExpandMore />}
				</ListItem>

				<Collapse
					className={classes.collapse}
					in={props.openCategory === "settings"}
					timeout="auto"
					unmountOnExit
				>
					{props.loadCity ? (
						<div className={classes.load}>
							<Loading color="#326dff" size={24} />
						</div>
					) : (
						<List component="div">
							{props.listCity.map((item) => {
								return (
									<div key={item.key}>
										<ListItem
											key={item.key}
											button
											onClick={() => props.chooseCity(item.key)}
											className={classes.nested}
											style={{
												color: props.city === item.key ? "#326dff" : "#000",
											}}
										>
											<ListItemText primary={item.address} />
											{props.city === item.key ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItem>

										<Collapse
											className={classes.collapse}
											in={props.city === item.key ? true : false}
											timeout="auto"
											unmountOnExit
										>
											<List component="div" className={classes.nestedLink}>
												{props.adminPermission &&
													!!props.adminPermission.delivery && (
														<NavLink
															to={`/page/delivery/${item.key}`}
															className={classes.link}
															activeClassName={classes.active}
														>
															<ListItem button className={classes.nested}>
																<ListItemIcon className={classes.iconMenu}>
																	<DepartureBoardIcon />
																</ListItemIcon>
																<ListItemText
																	primary={t("navigation.delivery")}
																/>
															</ListItem>
														</NavLink>
													)}
												{props.adminPermission &&
													props.adminPermission.workingHours && (
														<NavLink
															to={`/page/worktime/${item.key}`}
															className={classes.link}
															activeClassName={classes.active}
														>
															<ListItem button className={classes.nested}>
																<ListItemIcon className={classes.iconMenu}>
																	<AccessTime />
																</ListItemIcon>
																<ListItemText
																	primary={t("navigation.workingHours")}
																/>
															</ListItem>
														</NavLink>
													)}

												{props.adminPermission &&
													props.adminPermission?.menu &&
													props.listCity?.length > 1 && (
														<NavLink
															to={`/page/menu/categoriesCity/${item.key}`}
															className={classes.link}
															style={{
																background: isMenu
																	? "rgba(50, 109, 255, .1)"
																	: "inherit",
															}}
														>
															{" "}
															<ListItem button>
																<ListItemIcon className={classes.iconMenu}>
																	<AssignmentIcon
																		className={classes.icon}
																		style={{
																			color: isMenu ? "#326dff" : "inherit",
																			fill: isMenu ? "#326dff" : "",
																		}}
																	/>
																</ListItemIcon>
																<ListItemText
																	style={{
																		color: isMenu ? "#326dff" : "inherit",
																	}}
																	primary={t("navigation.stopList")}
																/>
															</ListItem>
														</NavLink>
													)}
												{props.adminPermission &&
													props.adminPermission.pickupControl && (
														<NavLink
															to={`/page/pickupControl/${item.key}`}
															className={classes.link}
															activeClassName={classes.active}
														>
															<ListItem button className={classes.nested}>
																<ListItemIcon className={classes.iconMenu}>
																	<RemoveShoppingCartIcon />
																</ListItemIcon>
																<ListItemText
																	primary={t("navigation.pickupControl")}
																/>
															</ListItem>
														</NavLink>
													)}
												{props.adminPermission &&
													(props.adminPermission.disableApp ||
														props.adminPermission.disableApp === undefined) && (
														<NavLink
															to={`/page/disableapp/${item.key}`}
															className={classes.link}
															activeClassName={classes.active}
														>
															<ListItem button className={classes.nested}>
																<ListItemIcon className={classes.iconMenu}>
																	<MobileOffIcon />
																</ListItemIcon>
																<ListItemText
																	primary={t("navigation.disableApp")}
																/>
															</ListItem>
														</NavLink>
													)}
												{props.adminPermission &&
													(props.adminPermission.openAppAlert ||
														props.adminPermission.openAppAlert ===
															undefined) && (
														<NavLink
															to={`/page/disablealert/${item.key}`}
															className={classes.link}
															activeClassName={classes.active}
														>
															<ListItem button className={classes.nested}>
																<ListItemIcon className={classes.iconMenu}>
																	<MobileOffIcon />
																</ListItemIcon>
																<ListItemText
																	primary={t("navigation.disableAlert")}
																/>
															</ListItem>
														</NavLink>
													)}
												{isValidPayCard && (
													<NavLink
														to={`/page/paycard/${item.key}`}
														className={classes.link}
														activeClassName={classes.active}
													>
														<ListItem button className={classes.nested}>
															<ListItemIcon className={classes.iconMenu}>
																<PaymentIcon />
															</ListItemIcon>
															<ListItemText primary={t("navigation.paycard")} />
														</ListItem>
													</NavLink>
												)}
												{payCardForZone && (
													<NavLink
														to={`/page/paycardforzone/${item.key}`}
														className={classes.link}
														activeClassName={classes.active}
													>
														<ListItem button className={classes.nested}>
															<ListItemIcon className={classes.iconMenu}>
																<PaymentIcon />
															</ListItemIcon>
															<ListItemText
																primary={t("navigation.paycardforzone")}
															/>
														</ListItem>
													</NavLink>
												)}

												{payCardForTerminal && (
													<NavLink
														to={`/page/paycardforterminal/${item.key}`}
														className={classes.link}
														activeClassName={classes.active}
													>
														<ListItem button className={classes.nested}>
															<ListItemIcon className={classes.iconMenu}>
																<PaymentIcon />
															</ListItemIcon>
															<ListItemText
																primary={t("navigation.paycardforterminal")}
															/>
														</ListItem>
													</NavLink>
												)}

												{props.adminPermission &&
													(props.adminPermission.saleFirstOrder ||
														props.adminPermission.promoCodes ||
														props.adminPermission.bannerControl) && (
														<NavLink
															to={validPromocodesUrl(item)}
															className={classes.link}
															activeClassName={classes.active}
														>
															<ListItem button className={classes.nested}>
																<ListItemIcon className={classes.iconMenu}>
																	<LoyaltyIcon />
																</ListItemIcon>
																<ListItemText
																	primary={t("navigation.loyalty")}
																/>
															</ListItem>
														</NavLink>
													)}
											</List>
										</Collapse>
									</div>
								);
							})}
						</List>
					)}
				</Collapse>

				{props.adminPermission && props.adminPermission.ourAdmin && (
					<ListItem button onClick={() => handleProductsClick()}>
						<ListItemIcon className={classes.iconMenu}>
							<FolderIcon />
						</ListItemIcon>
						<ListItemText
							className={classes.listText}
							primary={t("navigation.editMenu")}
						/>
						{props.openProducts === "products" ? (
							<ExpandLess />
						) : (
							<ExpandMore />
						)}
					</ListItem>
				)}

				{props.adminPermission && props.adminPermission.ourAdmin && (
					<Collapse
						className={classes.collapse}
						in={props.openProducts === "products"}
						timeout="auto"
						unmountOnExit
					>
						{props.preloader ? (
							<div className={classes.loader}>
								<Loading />
							</div>
						) : (
							<List component="div">
								<NavLink
									exact
									to={ROUTES.PRODUCTS}
									className={classes.link}
									activeClassName={classes.active}
								>
									<ListItem button className={classes.nested}>
										<ListItemText primary={t("navigation.categories")} />
									</ListItem>
								</NavLink>
								<NavLink
									exact
									to={ROUTES.ADD_PRODUCTS}
									className={classes.link}
									activeClassName={classes.active}
								>
									<ListItem button className={classes.nested}>
										<ListItemText primary={t("navigation.addProduct")} />
									</ListItem>
								</NavLink>
								<NavLink
									exact
									to={ROUTES.EDIT_PRODUCTS}
									className={classes.link}
									activeClassName={classes.active}
								>
									<ListItem button className={classes.nested}>
										<ListItemText primary={t("navigation.editProduct")} />
									</ListItem>
								</NavLink>
								{props.adminPermission.productsOrder && (
									<NavLink
										exact
										to={ROUTES.SORT_MODE_CATEGORIES}
										className={classes.link}
										activeClassName={classes.active}
									>
										<ListItem button className={classes.nested}>
											<ListItemText primary={t("navigation.productsOrder")} />
										</ListItem>
									</NavLink>
								)}
							</List>
						)}
					</Collapse>
				)}
			</List>
		</Drawer>
	);
};

export default Navigation;
