import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		// background: "#fff",
		padding: "20px 5px",
	},
	icon: {},
	iconUp: {
		rotate: "180deg",
	},
	container: {
		padding: 16,
		border: "1px solid #ccc",
		borderRadius: 8,
		marginBottom: 10,
		cursor: "pointer",
	},
	zoneName: {
		fontSize: 17,
		fontWeight: "bold",
		marginBottom: 8,
		color: "#476282",
		display: "flex",
		alignItems: "center",
	},
	zoneTitle: {
		fontSize: 18,
		marginBottom: 8,
		color: "#476282",
	},
	inputsContainer: {
		display: "flex",
		gap: "10px",
		marginTop: 15,
		flexWrap: "wrap",
	},
	saveButton: {
		marginTop: 20,
		backgroundColor: "#326DFF",
		color: "#fff",
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		"&:hover": {
			backgroundColor: "#2554cc",
		},
	},
}));

const PayCard = ({ zones, onSave }) => {
	const classes = useStyles();
	const [data, setData] = useState(zones);
	const [visibleZones, setVisibleZones] = useState({});
	const [openConfirm, setOpenConfirm] = useState(false); // Состояние модального окна
	const [isModified, setIsModified] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		setData(zones);
		setIsModified(false);
	}, [zones]);

	const toggleInputs = (zoneIndex) => {
		setVisibleZones((prev) => ({
			...prev,
			[zoneIndex]: !prev[zoneIndex],
		}));
	};

	const handleInputChange = (zoneIndex, paymentKey, fieldKey, value) => {
		setData((prevData) => {
			const newData = prevData.map((zone, index) =>
				index === zoneIndex
					? {
							...zone,
							cardPaymentSystem: {
								...zone.cardPaymentSystem,
								[paymentKey]: {
									...zone.cardPaymentSystem[paymentKey],
									[fieldKey]: value,
								},
							},
					  }
					: zone
			);
			setIsModified(JSON.stringify(newData) !== JSON.stringify(zones));
			return newData;
		});
	};

	useEffect(() => {
		if (zones) {
			setData(zones);
		}
	}, [zones]);

	const handleInputClick = (e) => {
		e.stopPropagation(); // Предотвращаем всплытие события
	};

	const handleSave = () => {
		setOpenConfirm(true); // Открываем модальное окно перед сохранением
	};

	const handleConfirmSave = () => {
		if (onSave) {
			onSave(data);
		}
		setOpenConfirm(false); // Закрываем модалку после подтверждения
		setIsModified(false);
	};

	const handleClose = () => {
		setOpenConfirm(false);
	};

	const dataNames = [
		"fondy",
		"liqPay",
		"wayForPay",
		"yandex",
		"portmone",
		"monobank",
	];

	return (
		<div>
			{data.map((zone, zoneIndex) => (
				<div
					key={zoneIndex}
					className={classes.container}
					onClick={() => toggleInputs(zoneIndex)}
				>
					<div className={classes.zoneName}>
						{zone.name}{" "}
						{visibleZones[zoneIndex] ? (
							<ExpandMoreIcon className={(classes.icon, classes.iconUp)} />
						) : (
							<ExpandMoreIcon className={classes.icon} />
						)}
					</div>
					{visibleZones[zoneIndex] && (
						<div>
							{zone.cardPaymentSystem &&
								Object.entries(zone.cardPaymentSystem).map(
									([paymentKey, fields]) => (
										<div key={paymentKey}>
											<div className={classes.zoneTitle}>
												{dataNames.includes(paymentKey) && paymentKey}
											</div>
											<div
												className={classes.inputsContainer}
												onClick={handleInputClick}
											>
												{Object.entries(fields).map(([fieldKey, value]) => (
													<TextField
														key={paymentKey + fieldKey}
														label={fieldKey}
														variant="outlined"
														size="small"
														style={{
															minWidth: isSmallScreen ? "100%" : "320px", // Адаптация инпутов
														}}
														value={value}
														onChange={(e) =>
															handleInputChange(
																zoneIndex,
																paymentKey,
																fieldKey,
																e.target.value
															)
														}
													/>
												))}
											</div>
										</div>
									)
								)}
						</div>
					)}
				</div>
			))}

			<Button
				variant="contained"
				className={classes.saveButton}
				onClick={handleSave}
				disabled={!isModified}
				style={{
					backgroundColor: isModified ? "#326DFF" : "#ccc",
					color: "#fff",
				}}
			>
				Зберегти
			</Button>

			{/* Модальное окно подтверждения */}
			<Dialog open={openConfirm} onClose={handleClose}>
				<DialogTitle>Підтвердити!</DialogTitle>
				<DialogContent>Ви підтверджуєте збереження змін?</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirmSave} color="primary">
						Так
					</Button>
					<Button onClick={handleClose} color="primary">
						Ні
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default PayCard;
