import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { withFirebase } from "../../firebase";
import PayCard from "./PayCard";

const PayCardForZone = ({ firebase }) => {
	const { city } = useParams(); // Получаем параметр city из урла
	const [zone, setZone] = useState([]);

	const getZone = () => {
		const refPath = `/settings/City/${city}/customDeliveryZone`;

		// Получаем обновленные данные
		firebase.bd
			.ref(`${refPath}`)
			.once("value")
			.then((snapshot) => {
				const data = snapshot.val();

				setZone(data || {}); // Если данных нет, устанавливаем пустой массив
			})
			.catch((error) => {
				console.error("Ошибка при получении данных:", error);
			});
	};

	useEffect(() => {
		getZone();
	}, [city]);

	const saveZoneData = (updatedData) => {
		const refPath = `/settings/City/${city}/customDeliveryZone`;

		firebase.bd
			.ref(refPath)
			.set(updatedData)
			.then(() => {
				console.log("Данные успешно сохранены");
				getZone(); // Обновляем данные после сохранения
			})
			.catch((error) => {
				console.error("Ошибка при сохранении данных:", error);
			});
	};

	return <PayCard zones={zone} onSave={saveZoneData} />;
};

export default withFirebase(PayCardForZone);
